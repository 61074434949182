import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Typography, Container, Grid } from "@material-ui/core"
import Gestion from "../assets/Footer/gestion.png"
import Apertura2 from "../assets/Footer/apertura2.png"
import Apertura from "../assets/Footer/apertura.png"

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em"
    },
    marginTop: "10em"
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64"
    }
  },
  media: {
    width: "100%"
  }
}))

export default function Index() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Solicitante - Tasas</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Tasas de CFL</b>
            </Typography>
          </Box>
          <Typography variant="h2" gutterBottom inline>
            Solicitante
          </Typography>
          {/* <Box mt={4} py={2}>
            <Typography variant="h3" gutterBottom inline>
              Comisión de Gestión
            </Typography>
            <Grid container spacing={2}justify="center" alignItems="center">
              <Grid  item xs={6}>
                <img
                  className={classes.media}
                  src={Gestion}
                  alt="comisión de gestión"
                />
              </Grid>
            </Grid>
          </Box> */}
          <Box mt={4} py={2}>
            <Typography variant="h3" gutterBottom inline>
              Comisión por Apertura
            </Typography>
            <Grid container spacing={2}>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify="center"
                alignItems="center"
              >
                <img
                  className={classes.media}
                  src={Apertura}
                  alt="comisión Apertura"
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justify="center"
                alignItems="center"
              >
                <img
                  className={classes.media}
                  src={Apertura2}
                  alt="comisión Apertura"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
